export default function (columns: any, userdata: any = {}) {
  if (columns?.length) {
    const valueColumnsFilter = columns?.filter((x: any) => _has(x, 'value'))
    const uservalueColumnsFilter = columns?.filter((x: any) => _has(x, 'uservalue'))

    const state = {}

    if (uservalueColumnsFilter?.length && userdata && _isObject(userdata)) {
      uservalueColumnsFilter.forEach((x: any) => {
        const xvalue = _get(userdata, x?.uservalue)
        _set(state, x?.name, xvalue)
      })
    }

    if (valueColumnsFilter?.length) {
      const state = {}
      valueColumnsFilter.forEach((x: any) => {
        _set(state, x?.name, x?.value)
      })
    }

    return state
  }

  return {}
}
